



























































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { getAppLanguage, unitFormat, isIOS, compare } from '@/common/utils'
import html2canvas from 'html2canvas'
import {
  callAppFunc_WebShare,
  callAppFunc_copyMess,
  callAppFunc_bindWechat,
  callH5Func_wechatCode,
  callAppFunc_closeCurrentWebview
} from '@/common/utils/bridge'
import {
  getInviteActivityInfo,
  accessInvite,
  wechatAuth,
  withdrawList,
  getInviteList,
  platformWithdrawList,
  withdraw
} from '@/pages/videoGame/api'
import { CommonModule } from '@/store/modules/common'
import QRCode from 'qrcode'
import LXKF_BTN from '@/pages/videoGame/assets/inviteActivity/pop_lxkf.png'
import {
  WXAPPID_IOS,
  WXSECRET_IOS,
  WXAPPID_ANDRID,
  WXSECRET_ANDRID
} from '@/config/index'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { codeLoginApi } from '@/pages/thirdSidePage/api'
@Component
export default class extends Vue {
  inviteInfo: any = {
    uid: '',
    nickname: '',
    avatar: '',
    inviteCode: '',
    beInvited: 1,
    registerInvite: '',
    registerBeInvited: '',
    buyAmount: '',
    buyAward: '',
    weChatAuth: 0,
    wechatCustomer: '',
    wechatNickname: '',
    limitTimeWithdraw: '',
    percent: ''
  }
  cashbackRule = [
    {
      scale: 0,
      rechargeRange: ''
    },
    {
      scale: 0,
      rechargeRange: ''
    },
    {
      scale: 0,
      rechargeRange: ''
    },
    {
      scale: 0,
      rechargeRange: ''
    }
  ]
  inviteCashbackCountVo = {
    totalMoney: '',
    cashbackItemVo: [
      {
        scale: 0,
        count: ''
      },
      {
        scale: 0,
        count: ''
      },
      {
        scale: 0,
        count: ''
      },
      {
        scale: 0,
        count: ''
      }
    ]
  }
  QRImgUrl = ''
  userName = CommonModule.userName
  inviteRecordList: any = []
  showCanvas = false
  language = getAppLanguage()
  //language = 'uyghur'

  inviteCode = ''
  showCodePop = false
  showRewardPop = false
  recordList = []
  inputCode = ''
  shareImageBase64: any = ''
  unitFormat = unitFormat
  commitCodeLoading = false
  recordType = 1 //1返现记录 2邀请记录
  swiperList: any = []
  cashbackList: any = []
  withdrawStatusMap: any = {
    1: '成功',
    2: '成功',
    3: '返现中',
    4: '失败'
  }
  LXKF_BTN = LXKF_BTN
  showUpdatePop = false
  showFailPop = false

  showWxPop = false
  showChangeWx = false
  callAppFunc_bindWechat = callAppFunc_bindWechat
  callAppFunc_closeCurrentWebview = callAppFunc_closeCurrentWebview
  wxCode = ''
  wxOauthInfo = {
    access_token: '',
    expires_in: 0,
    refresh_token: '',
    openid: '',
    scope: '',
    unionid: ''
  }
  wxUserInfo = {
    openid: '',
    nickname: '',
    sex: 1,
    province: '',
    city: '',
    country: '',
    headimgurl: '',
    privilege: [],
    unionid: ''
  }
  cashbakcLoading = false
  cashbackFinished = false
  inviteLoading = false
  inviteFinished = false
  cashbackPageInfo = {
    currPage: 0,
    pageSize: 20,
    total: 0
  }
  invitePageInfo = {
    currPage: 0,
    pageSize: 20,
    total: 0
  }
  failStatus: any = ''
  curRecordInfo: any = {}
  failStatusMap: any = {
    0: '成功',

    1: '未授权微信',
    2: '提现达到每日限额',
    3: '其他原因'
  }
  compareVersion = compare
  isAutoCashback = false

  async commitCode() {
    // TODO 提交邀请码
    if (this.commitCodeLoading) {
      return
    }
    this.commitCodeLoading = true
    const params = {
      inviteCode: this.inputCode,
      uid: this.inviteInfo.uid
    }
    try {
      const { data } = await accessInvite(params)
      this.showCodePop = false
      this.inputCode = ''
      this.showRewardPop = true
      this.getData()
    } finally {
      this.commitCodeLoading = false
    }
  }
  closeReward() {
    this.showRewardPop = false
  }

  async saveToImg() {
    //点击保存按钮的回调事件
    this.showCanvas = true

    await this.$nextTick()
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this: any = this
    setTimeout(() => {
      const element: any = this.$refs['canvasImg'] // 需要导出的页面
      console.log("this.$refs['canvasImg']", this.$refs['canvasImg'])
      html2canvas(element, {
        allowTaint: true,
        useCORS: true
      })
        .then(canvas => {
          // imgUrl 是图片的 base64格式 代码 png 格式
          const imgUrl = canvas.toDataURL('image/png')
          _this.shareImageBase64 = imgUrl
          this.downloadIamge(imgUrl, 'plantCardImg.png')
          //this.showCanvas = false
        })
        .catch(err => {
          console.log('saveToImg err', err)
        })
    }, 1000)
  }
  downloadIamge(imgsrc: string, name: string) {
    //下载图片地址和图片名
    const image = new Image()
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this: any = this
    // 解决跨域 Canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous')
    image.onload = function() {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const context: any = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)

      const url = canvas.toDataURL('image/png') //得到图片的base64编码数据
      const a = document.createElement('a') // 生成一个a元素
      const event = new MouseEvent('click') // 创建一个单击事件
      a.download = name || 'photo' // 设置图片名称
      a.href = url // 将生成的URL设置为a.href属性
      a.dispatchEvent(event) // 触发a的单击事件
      _this.shareImageBase64 = url
    }
    image.src = imgsrc
  }

  appShare() {
    const iosShareUrl =
      'https://qiniu.kigo.top/iOS-web/videoGame.html#/share?shareuid=' +
      +this.inviteInfo.uid
    const andridShareUrl =
      'https://' +
      window.location.host +
      '/h5/main/videoGame.html#/share?shareuid=' +
      this.inviteInfo.uid
    const shareUrl = isIOS ? iosShareUrl : andridShareUrl
    const registerBeInvited = this.inviteInfo.registerBeInvited
    const shareTitle = `中午吃什么？ 先领个红包，点个大的！`
    const shareDescribe = ''
    const shareImageUrl =
      'https://qiniu.kigo.top/h5resource/lQDPJx1uGjsTExTMgsyCsKx0nTMcvwOvBOq3F1wAoQA_130_130.jpg'
    const data = {
      title: shareTitle,
      describe: shareDescribe,
      imageUrl: shareImageUrl,
      webpageUrl: shareUrl,
      shareUrl: shareUrl,
      registerBeInvited: this.inviteInfo.registerBeInvited,
      inviteCode: this.inviteInfo.inviteCode
    }

    callAppFunc_WebShare(data)
  }

  async getData() {
    const params = {
      uid: CommonModule.userId || ''
    }
    const { data } = await getInviteActivityInfo(params)
    this.inviteInfo = data
    //this.inviteRecordList = data.inviteRecordList
    this.cashbackRule = data.cashbackRule
    this.inviteCashbackCountVo = data.inviteCashbackCountVo
  }

  copyTextToClipboard(str: string) {
    callAppFunc_copyMess(str)
  }
  goRule() {
    window.location.href = '/h5/main/user.html#/richH5?id=54'
  }
  handlWxBack(val: string) {
    console.log('handlWxBack', val)
    this.wxCode = val
    this.showWxPop = false
    this.showChangeWx = false
    this.showFailPop = false
    this.handlewechatAuth(val)
    //this.getWxAccessToken(val)
  }
  async getWxAccessToken(val: string) {
    axios
      .get('https://api.weixin.qq.com/sns/oauth2/access_token', {
        params: {
          appid: isIOS ? WXAPPID_IOS : WXAPPID_ANDRID,
          secret: isIOS ? WXSECRET_IOS : WXSECRET_ANDRID,
          code: val,
          grant_type: 'authorization_code'
        }
      })
      .then(res => {
        console.log('getWxAccessToken res', res)
      })
      .catch(err => {
        console.log('getWxAccessToken err', err)
      })
  }
  async getWxUserInfo() {
    try {
      const data: any = await axios.get(
        'https://api.weixin.qq.com/sns/userinfo',
        {
          params: {
            access_token: this.wxOauthInfo.access_token,
            openid: this.wxOauthInfo.openid,
            lang: 'zh_CN '
          }
        }
      )
      console.log('getWxUserInfo res', data)
      this.wxUserInfo = data
    } catch (err) {
      console.log('getWxUserInfo err', err)
    }
  }
  async handlewechatAuth(code: string) {
    const params = {
      code: code,
      uid: CommonModule.userId
    }
    const { data } = await wechatAuth(params)
    console.log('handlewechatAuth res', data)
    this.$toast(this.$t('授权成功'))
    this.getData()
    await withdraw({
      uid: CommonModule.userId,
      withdrawId: this.curRecordInfo.id || ''
    })
    this.cashbackList = []
    this.cashbackPageInfo.currPage = 1
    this.onCashbackLoad('refresh')
    //this.isAutoCashback = false
  }

  async onCashbackLoad(type?: string) {
    const params = {
      page: type == 'refresh' ? 1 : this.cashbackPageInfo.currPage + 1,
      limit: this.cashbackPageInfo.pageSize,
      uid: CommonModule.userId
    }
    const { data } = await withdrawList(params)
    if (this.cashbackList.length == 0) {
      this.cashbackList = data.list
    } else {
      this.cashbackList.push(...data.list)
    }
    // if (this.refreshing) {
    //   this.cashbackList = []
    //   this.refreshing = false
    // }
    this.cashbakcLoading = false

    this.cashbackPageInfo = data.pageInfo

    if (this.cashbackList.length >= this.cashbackPageInfo.total) {
      this.cashbackFinished = true
    }
  }
  async onInviteListLoad(type?: string) {
    const params = {
      page: type == 'refresh' ? 1 : this.invitePageInfo.currPage + 1,
      limit: this.invitePageInfo.pageSize,
      uid: CommonModule.userId
    }
    const { data } = await getInviteList(params)
    if (this.inviteRecordList.length == 0) {
      this.inviteRecordList = data.list
    } else {
      this.inviteRecordList.push(...data.list)
    }
    // if (this.refreshing) {
    //   this.cashbackList = []
    //   this.refreshing = false
    // }
    this.inviteLoading = false

    this.invitePageInfo = data.pageInfo

    if (this.inviteRecordList.length >= this.invitePageInfo.total) {
      this.inviteFinished = true
    }
  }
  withdrawStatusClick(item: any) {
    if (item.withdrawStatus == 4) {
      this.failStatus = item.failStatus
      this.curRecordInfo = item
      this.showFailPop = true
    }
  }
  async getSwiperList() {
    const params = {
      limit: 50,
      page: 1
    }
    const { data } = await platformWithdrawList(params)
    this.swiperList = data.list
  }
  hideUserName(str: string) {
    //
    if (!str) {
      return ''
    }
    let tmp = str[0]
    for (let i = 1; i < str.length; i++) {
      tmp += '*'
    }
    return tmp
  }
  handleFailWxAuth() {
    // this.isAutoCashback = true
    callAppFunc_bindWechat()
  }
  handlContactKF() {
    this.copyTextToClipboard(this.inviteInfo.wechatCustomer)
    this.showFailPop = false
  }
  handleGoWx() {
    if (!this.compareVersion(CommonModule.appVersion, '3.3.0')) {
      this.showUpdatePop = true
      return
    }
    callAppFunc_bindWechat()
  }
  handleGoWx2() {
    if (!this.compareVersion(CommonModule.appVersion, '3.3.0')) {
      this.showUpdatePop = true
      return
    }
    this.showWxPop = true
  }
  async mounted() {
    await CommonModule.getToken()
    if (!this.compareVersion(CommonModule.appVersion, '3.3.0')) {
      this.showUpdatePop = true
    }
    await this.getData()
    if (
      this.inviteInfo.weChatAuth != 1 &&
      this.compareVersion(CommonModule.appVersion, '3.3.0')
    ) {
      this.showWxPop = true
    }
    this.onInviteListLoad()
    this.getSwiperList()

    callH5Func_wechatCode(this.handlWxBack)
  }
}
